// TODO: merge with next-components/utils/format-phone -- utily is the same except this formats 'Private' numbers.`

/**
 * Convert E.164 '+15551234567' to '(555) 123-4567'
 * @param {string | null} [tn] E.164 phone number
 * @returns {string} friendly formatted phone number
 */
function formatPhone(tn) {
  if (!tn) return ''
  // format private numbers
  if (tn === '+266696687') return 'Private'

  // return original value if it doesn't match the E.164 format
  if (!/^\+1\d{10}$/.test(tn)) return tn

  // return formatted phone number
  return `(${tn.substring(2, 5)}) ${tn.substring(5, 8)}-${tn.substring(8)}`
}

module.exports = formatPhone
