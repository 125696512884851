/** @typedef {import('@abs-warranty/fontawesome-svg-core').IconDefinition} IconDefinition */

import { faFaceThinking } from '@abs-warranty/pro-regular-svg-icons'
import { snakeToCamel } from './snake-to-camel'

/**
 * An object representing a page that can provide it's URL and list of breadcrumbs
 */
class Page {
  /** @type {string} */
  #name

  /** @type {Page | undefined} */
  #parent

  /** @type {string} */
  #href

  /** @type {IconDefinition} */
  #icon

  /** @type {string} */
  #label

  /** @type {string | undefined} */
  #description

  /** @type {string | undefined} */
  #badge

  /** @type {boolean} */
  #fake

  /**
   * @param {Object} props
   * @param {Page} [props.parent] This Page's parent Page or undefined for no parent
   * @param {string} [props.href] The relative path for this page including a leading slash (/)
   * @param {IconDefinition} [props.icon] This Page's Font Awesome icon
   * @param {string} props.label The human friendly name for this page to be displayed in breadcrumbs
   * @param {string} [props.badge] Badge text (e.g: 'new!')
   * @param {string} [props.description] Longer description of the page's purpose
   * @param {boolean} [props.fake] Fake pages don't exist, they're just for organization
   */
  constructor({ parent, href, icon, label, badge, description, fake = false }) {
    this.#href = href ?? ''
    this.#label = label
    this.#icon = icon ?? faFaceThinking
    this.#parent = parent
    this.#badge = badge
    this.#description = description
    this.#fake = fake

    // generate a name from the href by converting snake case to camel case, defaults to $root if blank
    // e.g.: tire-protection --> tireProtection
    this.#name = snakeToCamel(this.#href)
  }

  /**
   * Get the full URL path for this Page assembled by traversing up the tree and combining parent Page paths
   *
   * @returns {string} Full URL path
   */
  get href() {
    return `${this.#parent?.href ?? ''}/${this.#href}`.replace('//', '/')
  }

  /**
   * Get an array of breadcrumb objects for this Page
   * ```
   *   label: {string} human friendly label,
   *   href: {string} full URL to the Page
   * ```
   *
   * @returns {Array<{ label: string, href: string | undefined}>} Array of breadcrumbs
   */
  get breadcrumbs() {
    return this.#parent
      ? [...this.#parent.breadcrumbs, { label: this.#label, href: this.#fake ? undefined : this.href }]
      : [{ label: this.#label, href: this.#fake ? undefined : this.href }]
  }

  /**
   * Get the human friendly label for this Page
   *
   * @returns {string} label
   */
  get label() {
    return this.#label
  }

  /**
   * Get this Page's Font Awesome icon
   *
   * @returns {import("@abs-warranty/fontawesome-svg-core").IconDefinition} icon
   */
  get icon() {
    return this.#icon
  }

  /**
   * Get this Page's parent Page
   *
   * @returns {Page | undefined} parent
   */
  get parent() {
    return this.#parent
  }

  /** @param {Page | undefined} page */
  set parent(page) {
    this.#parent = page
  }

  /**
   * @returns {string}
   */
  get name() {
    return this.#name
  }

  /**
   * @returns {string | undefined}
   */
  get badge() {
    return this.#badge
  }

  /**
   * @returns {string | undefined}
   */
  get description() {
    return this.#description
  }

  /**
   * @returns {boolean}
   */
  get fake() {
    return this.#fake
  }

  /**
   * Helper for logging
   *
   * @returns {string}
   */
  toString() {
    return JSON.stringify(
      {
        name: this.#name,
        href: this.#href,
        label: this.#label,
        icon: this.#icon?.iconName,
        parent: this.#parent?.name,
        fake: this.#fake,
      },
      null,
      2
    )
  }
}

export { Page }
