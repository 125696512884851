import { faSlack } from '@abs-warranty/free-brands-svg-icons/faSlack'
import { FontAwesomeIcon } from '@abs-warranty/react-fontawesome'

function Footer() {
  return (
    <footer className="bg-light mt-auto pt-3 pb-2 container-fluid d-print-none border-top">
      <ul className="list-inline mb-2">
        <li className="list-inline-item">Call Center Portal | Automotive Business Solutions</li>
        <li className="list-inline-item">
          <a href="https://github.com/abs-warranty/interchange" target="_blank" rel="noreferrer">
            v{process.env.VERSION} ({process.env.GIT_SHORT_SHA})
          </a>
        </li>
        <li className="list-inline-item">
          <a href="https://app.slack.com/client/T010L055Y0J/C01B4D55XQS" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faSlack} className="me-1" />
            #portal
          </a>
        </li>
      </ul>
    </footer>
  )
}

export default Footer
