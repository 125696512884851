import { FontAwesomeIcon } from '@abs-warranty/react-fontawesome'
import { faCircleUser } from '@abs-warranty/pro-duotone-svg-icons/faCircleUser'
import React, { useState } from 'react'

/**
 * Display Microsoft GraphAPI user profile photo if available or default to an icon
 *
 * @returns {JSX.Element}
 */
function UserPhoto() {
  const [isPhoto, setPhoto] = useState(true)

  return isPhoto ? (
    <img
      src="/api/v3/auth/photo?size=48x48"
      alt="Profile Photo"
      height="48"
      width="48"
      className="rounded-circle border border-1"
      onError={() => {
        setPhoto(false)
      }}
    />
  ) : (
    // 3x size is 48px
    <FontAwesomeIcon icon={faCircleUser} size="3x" className="text-primary" />
  )
}

export default UserPhoto
